<template>
  <div style="width: 100%;" :class="'toolbar-ctn ' + position" v-if="activeDesign">
    <div class="toolbar" v-if="position === 'top'">
      <div style="margin-left: 00px; margin-right: 10px; flex: 1 auto; align-items: center; display: flex; flex-wrap: nowrap;">
        <button class="clean" @click="closeFrame()" style="flex-direction: row; color: black;"><i class="fal fa-chevron-left" style="padding-right:10px;"></i> <span style="text-align: left;"><strong>{{ activeDesign.title.substring(0,18)+".." }}</strong><br><span style="opacity: 0.5;">{{ $t('product.select') }}</span></span></button>
        <div class="button-row">
          <sendButton></sendButton>
          <buyButton></buyButton>
        </div>
      </div>
    </div>
    <div class="BottomToolbar" v-if="position === 'bottom'">
      <div class="row column-mobile" v-if="activeDesign">
        <div class="row menumobile" v-if="activeElement === null">
          <!-- <button class="clean" @click="updateContentDimensions">Aanpassen</button> -->
          <!-- If no active element show these.. -->
          <v-swatches v-if="!activeElement" v-model="activeDesign.content[0].background" :swatches="swatches" show-border popover-y="top" show-fallback fallback-input-type="color" :style="{ background: activeDesign.content[0].background, borderRadius: '8px' }">
            <button class="clean" slot="trigger" readonly><i class="fal fa-palette" :style="{ color: invertedBackground }"></i>
                <label class="labelsmall" :style="{ color: invertedBackground }">{{ $t('icons.color') }}</label></button>
          </v-swatches>
          <button class="clean" @click="toggleSidebar('Photos')" v-if="!sidebar && !activeElement"><i class="fal fa-images"></i>
              <label class="labelsmall">{{ $t('icons.photos') }}</label></button>
          <button class="clean" @click="toggleSidebar('Quotes')" v-if="!sidebar && !activeElement"><i class="fal fa-quote-right"></i>
              <label class="labelsmall">{{ $t('icons.quotes') }}</label></button>
          <button class="clean" :class="{ active: (activeSub === 'uploads')}" v-if="mobile" @click="activeSub = 'uploads'"><i class="fal fa-cloud-upload"></i>
              <label class="labelsmall">{{ $t('icons.uploads') }}</label></button>
          <button class="clean"  :class="{ active: (activeSub === 'layout')}" v-if="!activeElement && mobile" @click="activeSub = 'layout'"><i class="fal fa-border-all"></i>
              <label class="labelsmall">{{ $t('icons.layout') }}</label></button>
          <button class="clean"  :class="{ active: (activeSub === 'gridLines')}" v-if="activeDesign.content[0].type === 'grid' && mobile" @click="activeSub = 'gridLines'"><i class="fal fa-border-inner"></i>
              <label class="labelsmall">{{ $t('icons.grid') }}</label></button>
          <button class="clean"  :class="{ active: (activeSub === 'borderSize')}" v-if="activeDesign.content[0].type === 'grid' && mobile" @click="activeSub = 'borderSize'"><i class="fal fa-border-outer"></i>
              <label class="labelsmall">{{ $t('icons.border') }}</label></button>
          <button class="clean" v-if="!activeElement && activeDesign.content[0].type !== 'grid'" @click="addText"><i class="fal fa-text"></i></button>
          <!-- <button class="clean" @click="clearDesign()" style="margin-left: auto; margin-right: 0px;"><i class="fal fa-empty-set" style="color: red;"></i></button> -->
        </div>
          <div class="row" v-if="activeElement !== null && activeElement.type !== 'background'" style="min-height: 44px; padding-left: 20px;">
            <v-swatches v-if="activeElement.type === 'text'" v-model="activeElement.content.color" show-border :swatches="swatches" show-fallback fallback-input-type="color"></v-swatches>
            <!-- Remove -->
            <button class="clean" v-if="activeElement !== null" @click="onElementRemove"><i
                class="far fa-trash-alt"></i><label class="labelsmall">{{ $t('icons.delete') }}</label></button>
                <button class="clean" @click="toggleSidebar" v-if="activeElement && activeElement.type !== 'text' && mobile"><i
              class="fal fa-images"></i><label class="labelsmall">{{ $t('icons.photos') }}</label></button>
            <button class="clean" :content="$t('system.tooltips.upload')" v-tippy="{ animateFill: false, animation : 'shift-toward'}" :class="{ active: (activeSub === 'uploads')}" v-if="mobile && activeElement.type !== 'text' " @click="activeSub = 'uploads'"><i class="fal fa-cloud-upload"></i><label class="labelsmall">{{ $t('icons.uploads') }}</label></button>
            <!-- Position -->
            <button class="clean" title="Position" v-if="menu !== 'position' && activeDesign.content[0].type !== 'grid'" @click="menu = 'position'">
              <i
                class="fal fa-bring-front"></i><label>{{ menu }}</label></button>
            <button class="clean" v-if="menu == 'position' && activeDesign.content[0].type !== 'grid'" @click="shiftPosition('down')"><i
                class="fal fa-send-backward"></i><label></label>
            </button>
            <button class="clean" v-if="menu == 'position' && activeDesign.content[0].type !== 'grid'" @click="shiftPosition('up')"><i
                class="fal fa-bring-forward"></i><label></label></button>

            <button :class="{ active: (activeSub === 'filters')}" v-if="activeElement && activeElement.type === 'image' && mobile" @click="activeSub = 'filters'" class="clean">
              <i class="fal fa-magic"></i>
              <label class="labelsmall">{{ $t('icons.filter') }}</label>
            </button>
            <tippy
                interactive
                :animate-fill="false"
                placement="bottom"
                distant="7"
                theme="light"
                animation="fade"
                trigger="click"
                v-if="!mobile"
                arrow
                @click="activeSub = 'filters'"
                >
            <template v-slot:trigger>
            <button :class="{ active: (activeSub === 'filters')}" :content="$t('system.tooltips.filters')" v-tippy="{ animateFill: false, animation : 'shift-toward'}" v-if="activeElement && activeElement.type === 'image'" @click="activeSub = 'filters'" class="clean">
            <i class="fal fa-magic"></i>
              <label class="labelsmall">{{ $t('icons.filter') }}</label>
            </button>
            </template>
                <imageFilters></imageFilters>
            </tippy>
            <!-- <tippy
                interactive
                :animate-fill="false"
                placement="bottom"
                distant="7"
                theme="light"
                animation="fade"
                trigger="click"
                arrow
                @click="activeSub = 'filters'"
                >
            <template v-slot:trigger>
            <button :class="{ active: (activeSub === 'crop')}" :content="$t('system.tooltips.crop')" v-tippy="{ animateFill: false, animation : 'shift-toward'}" v-if="activeElement && activeElement.type === 'image'" @click="activeSub = 'crop'" class="clean">
            <i class="fal fa-crop"></i>
            </button>
            </template>
              <imageCrop v-if="activeElement && activeElement.type === 'image' && activeSub === 'crop'"></imageCrop>
            </tippy> -->
            <button :class="{ active: (activeSub === 'crop')}" v-if="activeElement && activeElement.type === 'image'" @click="activeSub = 'crop'" class="clean">
                <i class="fal fa-crop"></i>

              <label class="labelsmall">{{ $t('icons.crop') }}</label>
            </button>

            <imageCrop v-on:update:activeSub="activeSub = $event" v-if="activeElement && activeElement.type === 'image' && activeSub === 'crop'"></imageCrop>

            <div v-if="activeElement && activeElement.type === 'text'"
            style="display: flex; flex-direction: row; align-items: center; gap: 1px;">
            <button :class="{ active: (fontOption === 'edit')}" class="clean mobile-only" @click="fontOption = 'edit'"><i class="fal fa-keyboard"></i></button>
            <button :class="{ active: (fontOption === 'font')}" class="clean" @click="fontOption = 'font', optionsActive = true"><i class="fal fa-font"></i></button>
            <button :class="{ active: (fontOption === 'size')}" class="clean" v-if="mobile" @click="fontOption = 'size', optionsActive = true"><i class="fal fa-text-size"></i></button>
            <!-- <button @click="font(-1)" style="border-top-right-radius: 0; border-bottom-right-radius: 0;"><i
                class="fas fa-minus" /></button>
            <input style="max-width: 48px; text-align: center;" v-model="activeElement.obj.fontSize" type="number" />
            <button @click="font(1)" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"><i
                class="fas fa-plus" /></button> -->
          </div>
        </div>
        <div class="row mobile-visible" v-if="mobile">
          <uploadQuick v-if="!activeElement && activeSub === 'uploads'"></uploadQuick>
          <uploadQuick v-if="!activeElement && activeSub === null"></uploadQuick>
          <layouts v-if="!activeElement && activeSub === 'layout'"></layouts>
          <uploadQuick v-if="activeElement && activeElement.type === 'image' && activeSub === 'uploads'"></uploadQuick>
          <uploadQuick v-if="activeElement && activeElement.type === 'image' && activeSub === null"></uploadQuick>
          <uploadQuick v-if="activeElement && activeElement.type === 'empty' && activeSub === null"></uploadQuick>
          <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="fontOption"></fontsQuick>
          <imageFilters v-if="activeElement && activeElement.type === 'image' && activeSub === 'filters'"></imageFilters>
          <gridSize v-if="!activeElement && activeSub === 'gridLines'"></gridSize>
          <borderSize v-if="!activeElement && activeSub === 'borderSize'"></borderSize>
        </div>
      </div>
    </div>
    <div class="options-menu" v-if="activeElement && activeElement.type != 'text' && position === 'bottom' && !mobile && layout">
      <button @click="activeSub = null">{{ $t('system.labels.done') }}</button>
      <imageFilters v-if="activeElement && activeElement.type === 'image' && activeSub === 'filters'"></imageFilters>
      <imageCrop v-if="activeElement && activeElement.type === 'image' && activeSub === 'crop'"></imageCrop>
    </div>
    <div class="options-menu" v-else-if="activeElement && activeElement.type === 'text' && position === 'bottom' && !mobile">
      <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="'edit'"></fontsQuick>
      <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="'size'"></fontsQuick>
      <fontsQuick v-if="activeElement && activeElement.type === 'text'" :type="'font'"></fontsQuick>
    </div>
    <div class="options-menu" v-else-if="!activeElement && position === 'bottom' && activeSub === 'layout' && !mobile">
      <button @click="activeSub = null">Done</button>
      <layouts></layouts>
      <gridSize></gridSize>
      <borderSize></borderSize>
    </div>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
// import { mapState } from 'vuex'
import artboardCreator from '@/helpers/artboard-creator'
import elementsLoader from '@/helpers/elements'
import previewCreator from '@/helpers/previewCreator'
import newStore from '@/store/newstore'

import borderSize from './Components/bordersize.vue'
import buyButton from './Components/buy-button.vue'
import imageCrop from './Components/crop.vue'
import imageFilters from './Components/filters.vue'
import fontsQuick from './Components/fonts.vue'
import gridSize from './Components/gridsize.vue'
import layouts from './Components/layouts.vue'
import sendButton from './Components/send-button.vue'
import uploadQuick from './Components/uploads.vue'

// import gridCreator from '@/helpers/GridCreator'

function invertColor (hex, bw) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  var r = parseInt(hex.slice(0, 2), 16)
  var g = parseInt(hex.slice(2, 4), 16)
  var b = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b)
}
function padZero (str, len) {
  len = len || 2
  var zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export default {
  components: {
    VSwatches, uploadQuick, fontsQuick, imageFilters, buyButton, sendButton, layouts, gridSize, borderSize, imageCrop
  },
  props: {
    position: String
  },
  data: function () {
    return {
      iframe: false,
      options: {
        name: 'Artboard'
      },
      layout: false,
      fontOption: 'edit',
      activeSub: 'layout',
      optionsActive: false,
      gridlines: false,
      filters: false,
      fontFamily: 'Roboto',
      fontSize: '24px',
      swatches: [
        ['#FFFFFF', '#000000'],
        ['#F493A7',
          '#9196f8',
          '#FFCCD5',
          '#262A53',
          '#FFA0A0',
          '#FFE3E3',
          '#053742',
          '#39A2DB',
          '#A2DBFA',
          '#E8F0F2',
          '#0A1931',
          '#185ADB',
          '#FFC947',
          '#EFEFEF',
          '#628395']
      ],
      shapeColor: '#000000',
      menu: null,
      loaded: false
    }
  },
  methods: {
    clearDesign () {
      this.$confirm(
        {
          message: 'Are you sure? This will remove your progress',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          callback: confirm => {
            if (confirm) {
              // ... do something
              if (this.activeDesign.content[0].type !== 'grid') {
                this.activeDesign.content[0].items = []
                newStore.commit('activeDesign', this.activeDesign)
              } else {
                // Remember grid and make new with empty...
              }
            }
          }
        }
      )
    },
    updateContentDimensions () {
      artboardCreator.methods.updateDimensions()
    },
    closeFrame () {
      previewCreator.create('session_preview')
      const interval = setInterval(function () {
        if (newStore.state.preview !== null) {
          window.parent.postMessage(
            {
              event_id: 'preview',
              title: newStore.state.activeDesign.title,
              preview: newStore.state.preview.canvasBlob ? newStore.state.preview.canvasBlob : ''
            },
            '*'
          )
          window.parent.postMessage('closing the frame', '*')
          clearInterval(interval)
        }
      }, 250)
    },
    addText () {
      elementsLoader.methods.addText()
    },
    editText () {
      this.activeElement.editable = true
    },
    toggleSidebar (title) {
      newStore.commit('sidebar', true)
      newStore.commit('overrideMenu', title)
      //  this.$bus.$emit('new-user-question', this.newUserQuestion)
      // this.$bus.$emit('override-menu', 'Quotes')
      // this.$emit('overrideMenu', 'Quotes')
      // this.sidebar = !this.sidebar
    },
    changeMode () {
      this.activeDesign.content[0].type = (this.activeDesign.content[0].type === 'Grid') ? 'Free' : 'Grid'
      this.activeGrid.opacity = 1
    },
    changeGrid (value) {
      this.activeDesign.content[0].gridSize = value
    },
    convertFile () {
      console.log('test')
    },
    callUndo () {
      newStore.dispatch('undoDesign')
    },
    callRedo () {
      newStore.dispatch('redoDesign')
    },
    changeSize () {
      this.activeElement.width = 400
    },
    font (i) {
      const increase = Number(this.activeElement.content.fontSize) + i
      this.activeElement.obj.fontSize = increase
    },
    textEdit () {
      this.activeElement.editable = true
    },
    photoEffect () {
      this.$store.commit('checkFilters')
    },
    editable () {
      this.activeElement.editable = !this.activeElement.editable
    },
    swapArrayElements (arr, indexA, indexB) {
      const temp = arr[indexA]
      arr[indexA] = arr[indexB]
      arr[indexB] = temp
      return arr
    },
    shiftPosition (direction) {
      if (this.activeDesign.content[0].items.length > 1) {
        const currPos = this.activeDesign.content[0].items.indexOf(this.activeElement)
        this.changePosition(currPos, direction)
      }
      // if (this.activeDesign.content[0].items.indexOf(this.activeElement) > 1 && !i) {
      //   const currPos = this.activeDesign.content[0].items.indexOf(this.activeElement)
      //   this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos - 1))
      //   // this.$store.commit('changeOrderElement', this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos - 1)))
      // }
      // if (this.activeDesign.content[0].items.indexOf(this.activeElement) >= 1 && i) {
      //   const currPos = this.activeDesign.content[0].items.indexOf(this.activeElement)
      //   if (this.activeDesign.content[0].items.length !== (currPos + 1)) {
      //     this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos + 1))
      //     // this.$store.commit('changeOrderElement', this.swapArrayElements(this.activeDesign.content[0].items, currPos, (currPos + 1)))
      //   }
      // }
      // newStore.commit('activeDesign', this.activeDesign)
    },
    changePosition (currentIndex, direction) {
      var array = this.activeDesign.content[0].items
      let newPosition
      let mode = 'move'
      switch (direction) {
        case 'up':
          newPosition = currentIndex + 1
          break
        case 'down':
          newPosition = currentIndex - 1
          break
        case 'top':
          newPosition = array.length - 1 // calculate the end of the line
          break
        case 'bottom':
          newPosition = 0 // put it all the way to the start
          break
        case isNaN(direction) || direction:
          // console.log('number')
          mode = 'swap'
          newPosition = direction
          break
        default:
          newPosition = currentIndex + 1
      }

      // end reference is the index + 1 to splice it
      var currentIndexEnd = currentIndex + 1
      // reference the object to change
      var slice = array.slice(currentIndex, currentIndexEnd)[0]

      if (mode === 'move') {
        // remove the item from the list
        array.splice(currentIndex, 1)
        // put the item back in the list up or down
        array.splice(newPosition, 0, slice)
        // log the things
        // console.log(slice) // this is the reference object - can be removed after.
        // console.log(array) // this is the new array list
      }

      if (mode === 'swap') {
        // get old content - position, dimension
        var oldSlice = array.slice(currentIndex, currentIndexEnd)[0].text // content
        var newSlice = array.slice(newPosition, newPosition + 1)[0] // object

        // Content from the new position
        slice.text = newSlice.text

        // set old data into the old new position
        newSlice.text = oldSlice

        console.log(array) // this is the new array list
      }
      // newStore.state.activeDesign.content[0].items = array

      // TODO Dispatch new Design at the end of the run.
      newStore.commit('activeDesign', this.activeDesign)
    },
    lock () {
      this.$store.commit('lockElement')
    },
    unlock () {
      this.$store.commit('unlockElement')
    },
    removeArtBoard () {
      this.$store.commit('activeArtBoard', null)
    },
    onElementRemove (element) {
      // this.$store.commit('removeElementToArtBoard', this.activeElement)
      // this.$store.commit('setActivateElement', null)
      // const i = this.activeDesign.content[0].items.indexOf(element)
      // this.activeDesign.content[0].items.splice(i, 1)
      if (this.activeDesign.content[0].type !== 'grid') {
        this.activeDesign.content[0].items.splice(this.activeDesign.content[0].items.indexOf(newStore.state.activeElement), 1)
      } else {
        this.activeElement.type = 'empty'
        this.activeElement.content = {}
      }
      newStore.state.activeElement = null
    }
  },
  mounted () {
    if (!this.mobile && this.position === 'bottom') {
      this.activeSub = null
      // window.addEventListener('keypress', function (e) {
      //   self.activeElement.content.editable = true
      // })
    }
    if (window.location !== window.parent.location) {
      this.iframe = true
    }
  },
  watch: {
    activeElement (state, old) {
      // newStore.commit('activeDesign', this.activeDesign)
      // this.$store.commit('activeArtBoard', this.activeDesign)
    }
  },
  computed: {
    mobile () {
      return newStore.state.mobile
    },
    sidebar () {
      return newStore.state.sidebar
    },
    user (state) {
      return newStore.state.user
    },
    userType (state) {
      return newStore.state.userType
    },
    activeDesign () {
      return newStore.state.activeDesign
    },
    activeElement () {
      const self = this
      if (newStore.state.activeElement !== null) {
        self.activeSub = null
        self.optionsActive = false
      }
      return newStore.state.activeElement
    },
    color () {
      let color = 'white'
      if (newStore.state.activeDesign) {
        color = newStore.state.activeDesign.content[0].background
      }
      return color
    },
    activeGrid () {
      return newStore.state.activeGrid
    },
    invertedBackground () {
      const colorToInvert = newStore.state.activeDesign.content[0].background
      const inverted = invertColor(colorToInvert, true)
      return inverted
    }

    // mapState({
    //   activeElement (state) {
    //     return state.artBoardStore.activeElement
    //   },
    //   activeDesign (state) {
    //     return state.artBoardStore.artBoards
    //   },
    //   activeProduct (state) {
    //     return state.productsStore.product
    //   },
    //   color () {
    //     return this.activeDesign && this.activeDesign.content[0].background
    //   }
    // })
  }
}
</script>

<style lang="scss">
.button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-left: auto;
}
button {
  position: relative;
  i {
    color: black;
  }
  &.active i {
    color: #157CFD;
  }
}
.labelsmall {
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: translate(-50%, 0%);
  font-size: 10px;
  color: black;
  opacity: 0.6;
}
.options-menu {
  position: fixed;
  left: 90px;
  top: 20px;
  bottom: 20px;
  width: 425px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  justify-content: left;
  z-index: 11;
  .scrollable-row {
    display: grid;
    grid-gap: 0.5rem;
    height: auto;
    width: 100%;
    grid-template-columns: repeat(4, 20.9%);
    overflow-x: initial;
    justify-content: center;
    align-items: flex-start;
  }
}

.toolbar-ctn {
  position: absolute;
  box-sizing: border-box;
  &.top {
      z-index: 2;
  }
}
.toolbar {
  width: 100%;
  top: 0;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: row;
  padding: 00px;
  font-size: 1em;
  margin-top: 10px;
  z-index: 1;

  button {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    label {
      font-size: 0.8em;
      cursor: pointer;
    }
  }

  i {
    font-size: 18px;
  }

  .tb-item {
    background: #ccc;
    height: auto;
    width: auto;
    border-radius: 99px;
    margin-right: 5px;
    padding: 6px 12px;
  }

}

  .BottomToolbar {
    position: sticky;
    position: -webkit-sticky;
    bottom: 20px;
    padding-left: 0px;
    margin-left: 0px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    padding: 00px;
    font-size: 1em;
    margin-top: 10px;
    z-index: 10;
    button {
      font-size: 16px;
    }
  }

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  padding:0 10px;
  box-sizing: border-box;
    align-items: center;

  .scroller {
    overflow-x: scroll;
    -webkit-overflow-scrolling: auto;
  }

  .scroller::-webkit-scrollbar {
    display: none;
  }

  .leftLine {
  }
}

.font-picker {
  display: none !important;
  position: inherit !important;
  width: 240px !important;
  display: flex !important;
  align-items: center;
}

.font-picker ul {
  width: 240px !important;
}

.font-picker ul.expanded {
  max-height: 200px !important;
  margin-top: -260px !important;
  width: 240px !important;
  border-radius: 16px !important;
  background: white !important;
}

.vue-swatches__trigger__wrapper {
  display: inherit !important;
}
.mobile-visible {
  display: flex;
  bottom: 0px;
}

.column-mobile {
  flex-direction: column;
}
.mobile-only {
  display: none;
}

@media only screen and (max-width: 760px){
  .mobile-only {
    display: initial;
  }
  .mobile-visible {
    display: flex;

    height: 120px;
  }
  .row  {

      padding: 0;
  }
  .menumobile {
    padding: 0 20px;
  }
  .toolbar {
    &.bottom {
      width: calc(100%);
      margin-left: 0px;
      border-left: 1px solid #e6e3e3;
    }

    button {
      flex-direction: column;
    }

    .row {
      width: 100%;
      gap: 10px;
    }
  }

  .column-mobile {
    flex-direction: column;
  }

  .toolbar-ctn {
    &.top {
      z-index: 2;
    }
  }
  .BottomToolbar {
    height: auto;
    margin-left: 0px;
  }

  .font-picker {
    z-index: 10;
  }
}

.dropdown-button {
  background: none !important;
  width: auto;

  &:focus {
    background: none;
  }
}

.custom-font-picker {
  box-shadow: none;
  width: 200px;
}
.gridSize .active {
  background: white;
}
</style>
